.base-wrap {
  width: 100%;
  position: relative;
}
.base-main {
  margin-bottom: 128px;
}
.base-inner {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 16px;
}
.base-headline {
  margin-top: calc(48px + 16px);
  margin-bottom: 48px;
}
.base-headline p {
  font-family: 'Raleway', 'ヒラギノ角ゴ Pro', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo',
    sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-align: center;
}
.base-singleTop {
}
.base-column {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.base-column__main {
  width: calc(100% - 320px - 32px);
}
.base-column__main__section {
}
.base-column__main__section + .base-column__main__section {
  margin-top: 64px;
}
.base-column__ttl {
  width: 100%;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.base-column__ttl::before,
.base-column__ttl::after {
  content: '';
  width: 160px;
  height: 1px;
  background: #dbd4ce;
  flex-shrink: 0;
}

.base-column__ttl h2 {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
}
.base-column__ttl p {
  font-size: 14px;
  font-family: 'Raleway';
  text-align: center;
}
.base-column__calendar {
  width: 320px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(60, 64, 67, 0.1);
  position: sticky;
  top: 16px;
}
.base-column__spFixed {
  display: none;
}
.breadcrumb-link {
  color: #aaa;
}
@media screen and (max-width: 768px) {
  .base-headline {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .base-singleTop {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  .base-column {
    margin-top: 16px;
    display: block;
    padding: 0;
  }
  .base-column__main {
    width: 100%;
    padding: 0 16px;
  }
  .base-column__main__section + .base-column__main__section {
    margin-top: 32px;
  }
  .base-column__ttl {
    margin-bottom: 16px;
  }
  .base-column__ttl::before,
  .base-column__ttl::after {
    width: 32px;
  }
  .base-column__ttl h2 {
    font-size: 20px;
  }
  .base-column__ttl p {
    font-size: 12px;
  }
  .base-column__calendar {
    position: relative;
    top: 0;
    margin: 32px auto 0 auto;
  }
  .base-column__spFixed {
    width: 100%;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    bottom: 0;
    z-index: 50;
  }
  .base-column__spFixed::before {
    content: '';
    width: 100%;
    height: 50%;
    background: linear-gradient(to bottom, rgba(249, 249, 249, 0) 0%, rgba(249, 249, 249, 1) 100%);
    position: absolute;
    top: 0;
    left: 0;
  }
  .base-column__spFixed::after {
    content: '';
    width: 100%;
    height: 50%;
    background: #f9f9f9;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .base-column__spFixed__btn {
    width: 280px;
    height: 48px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
  }
}

.color-txt {
  color: #2e1f12 !important;
}
.color-txt--white {
  color: #fff !important;
}
.color-bg {
  background: #f9f9f9;
  background: var(--theme-background-color);
}
.color-bg--white {
  background: #fff;
  background: var(--theme-header-background-color);
}
.color-bg--black {
  background: #2e1f12;
  background: var(--theme-footer-background-color);
}
.color-bor--white {
  border-color: #fff;
  border-color: var(--theme-header-background-color);
}
.color-bor--black {
  border-color: #2e1f12;
  border-color: var(--theme-footer-background-color);
}
/* color change */
.color-main--txt {
  color: #bb0000;
  color: var(--theme-primary-color);
}
.color-sub--txt {
  color: #ab5411;
  color: var(--theme-secondary-color);
}
.color-main--bor {
  border-color: #bb0000;
  border-color: var(--theme-primary-color);
}
.color-sub--bor {
  border-color: #ab5411;
  border-color: var(--theme-secondary-color);
}
.color-main--bg {
  background: #bb0000;
  border-color: var(--theme-primary-color);
}
.color-sub--bg {
  background: #ab5411;
  border-color: var(--theme-secondary-color);
}

/*------------------------------------------------------------------------------
utility
------------------------------------------------------------------------------*/
.base-padding__8-8 {
  padding: 8px;
}
.base-padding__8-16 {
  padding: 8px 16px;
}
.base-padding__16-8 {
  padding: 16px 8px;
}
.base-padding-16 {
  padding: 16px;
}
.base-padding-16-32 {
  padding: 16px 32px;
}
.base-padding-32-16 {
  padding: 32 16px;
}

.base-marginTop-8 {
  margin-top: 8px;
}
.base-marginTop-16 {
  margin-top: 16px;
}
.base-marginTop-32 {
  margin-top: 32px;
}

.base-marginBottom-8 {
  margin-bottom: 8px;
}
.base-marginBottom-16 {
  margin-bottom: 16px;
}
.base-marginBottom-32 {
  margin-bottom: 32px;
}
